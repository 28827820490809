/* You can add global styles to this file, and also import other style files */
html,
body {
  padding: 0;
  margin: 0;
}

body {
  overflow-x: hidden; // add so tables don't over flow on scroll for small screens
}
